<template>
  <div>
    <h3>Radar Chart Example in Vue</h3>
    <radar-chart></radar-chart>
  </div>
</template>
<script>
import RadarChart from '../charts/Radar'

export default {
  components: {
    RadarChart
  }
}
</script>
